@import "../scss/variables";

@charset "UTF-8";

/*------------------------------------------------------------------
[Skin-modes Stylesheet]

Project        :   Yoha - html5 bootstrap admin template
Version        :   V.1
Create Date    :   03/06/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/*------------- Sidemenu- background Images-------------*/

.sidemenu-bgimage {
  .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      background: rgba(235, 238, 245, 0.9);
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .side-header {
    border-bottom: 2px solid transparent;
  }

  &.color-menu {
    .app-sidebar {
      background: url(../images/media/bg.jpg) !important;
      background-size: cover;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .side-header {
      border-bottom: 2px solid transparent;
    }
  }

  &.light-menu .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      background: rgba(255, 255, 255, 0.95);
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &.dark-menu {
    .app-sidebar {
      background: url(../images/media/bg.jpg) !important;
      background-size: cover;

      &:before {
        content: '';
        background: rgba(30, 40, 62, 0.89);
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .side-header {
      border-bottom: 2px solid transparent;
    }
  }

  &.gradient-menu {
    .app-sidebar {
      background: url(../images/media/bg.jpg) !important;
      background-size: cover;

      &:before {
        content: '';
        background: linear-gradient(to top, rgba(68, 124, 236, 0.94) 0%, rgba(164, 77, 231, 0.94) 100%) !important;
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .side-header {
      border-bottom: 2px solid transparent;
    }
  }
}

/*color-menu*/

/*light-menu*/

/*Dark-menu*/

/*Garident-menu*/

/* light-menu */

.light-menu {
  .app-sidebar {
    background: $white !important;
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      fill: $white;
    }
  }

  &.light-hor-header.white-mode .side-header {
    background: $white !important;
  }
}

/*color-menu*/

.light-mode.sidemenu-bgimage.color-menu .app-sidebar {
  background: url(../images/media/bg.jpg) !important;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.dark-mode.light-menu.sidemenu-bgimage {
  .side-header {
    border-right: 1px solid rgba(211, 220, 233, 0.5) !important;
    border-bottom: 2px solid transparent !important;
  }

  .side-menu__icon {
    fill: #677ca7 !important;
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      fill: #c5c9e6 !important;
    }
  }
}

/*light-menu*/

.light-mode.sidemenu-bgimage {
  &.light-menu .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      background: rgba(255, 255, 255, 0.95);
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &.dark-menu .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      background: rgba(30, 40, 62, 0.89);
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &.gradient-menu .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      background: linear-gradient(to top, rgba(68, 124, 236, 0.94) 0%, rgba(164, 77, 231, 0.94) 100%) !important;
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

/*Dark-menu*/

/*Garident-menu*/

/*light-mode*/

.sidemenu-bgimage {
  &.light-mode .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background: rgba(235, 238, 245, 0.9);
    }
  }

  &.white-mode .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      background: rgba(227, 228, 237, 0.9);
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &.dark-mode .app-sidebar {
    background: url(../images/media/bg.jpg) !important;
    background-size: cover;

    &:before {
      content: '';
      background: rgba(24, 27, 49, 0.96);
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

/*white-mode*/

/*Dark-mode*/

/*------------- Left-menu -------------*/

/*color-menu*/

.color-menu {
  .app-sidebar .sidebar-navs, .side-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-navs a {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: rgba(255, 255, 255, 0.4);
    }

    color: $white;
  }

  .side-header {
    .header-brand-img {
      &.light-logo1 {
        display: none;
      }

      &.desktop-logo {
        display: block;
      }
    }

    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .side-menu h3 {
    color: rgba(255, 255, 255, 0.3);
    opacity: 9;
  }

  .slide-menu {
    li.active:before {
      background: rgba(255, 255, 255, 0.1);
    }

    a.active {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .side-menu .side-menu__icon, .side-menu__item, .slide-item {
    color: rgba(255, 255, 255, 0.7);
  }

  .slide-menu li:before {
    background: rgba(255, 255, 255, 0.5);
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      fill: $white;
    }
  }

  .side-menu__icon {
    fill: rgba(255, 255, 255, 0.7) !important;
  }
}

/*Dark-menu*/

.dark-menu {
  .app-sidebar {
    background: #1e283e  !important;
  }

  .side-header {
    background: #1e283e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .slide-item {
    color: #c5c9e6;
  }

  .slide-menu li:before {
    border: 1px solid #c5c9e6;
    opacity: 0.7;
  }

  .side-header .header-brand-img {
    &.light-logo1 {
      display: none;
    }

    &.desktop-logo {
      display: block;
    }
  }

  &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .header-brand-img {
    &.light-logo1 {
      display: none !important;
    }

    &.desktop-logo {
      display: block !important;
    }
  }

  .side-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  }

  .app-sidebar .sidebar-navs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-navs a {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: #c5c9e6 !important;
  }

  .side-menu h3 {
    color: #c5c9e6;
    opacity: .4;
  }

  .slide-menu {
    li.active {
      &:before {
        background: rgba(255, 255, 255, 0.1);
      }

      &:after {
        background: rgba(255, 255, 255, 0.5);
      }
    }

    a.active {
      color: #c5c9e6;
    }
  }

  .side-menu .side-menu__icon {
    fill: #c5c9e6;
  }

  .side-menu__item, .slide-item {
    color: #c5c9e6;
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      fill: $white;
    }
  }
}

/*gradient-menu*/

.gradient-menu {
  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      fill: $white;
    }
  }

  .side-menu__icon {
    fill: rgba(255, 255, 255, 0.7) !important;
  }

  .app-sidebar {
    background: linear-gradient(to top, $primary 0%, #a44de7 100%) !important;
  }

  .side-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-navs a {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  &.slide-item.active {
    color: rgba(255, 255, 255, 0.4);
  }
}

.color-menu .slide-item:hover {
  color: rgba(255, 255, 255, 0.4);
}

.gradient-menu {
  .slide-item {
    &:focus {
      color: rgba(255, 255, 255, 0.4);
    }

    color: $white;
  }

  .side-header .header-brand-img {
    &.light-logo1 {
      display: none;
    }

    &.desktop-logo {
      display: block;
    }
  }
}

.sidebar-mini.sidenav-toggled.gradient-menu {
  .side-header {
    .toggle-logo {
      display: block !important;
    }

    .light-logo {
      display: none !important;
    }
  }

  &.sidenav-toggled-open .side-header {
    .toggle-logo {
      display: none !important;
    }

    .header-brand-img {
      &.light-logo1 {
        display: none !important;
      }

      &.desktop-logo {
        display: block !important;
      }
    }
  }
}

.gradient-menu {
  .slide-menu li:before {
    background: rgba(255, 255, 255, 0.5);
  }

  .side-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	background: #984fe6;
  }

  .side-menu h3 {
    color: rgba(255, 255, 255, 0.3);
    opacity: 9;
  }

  .slide-menu {
    li.active {
      &:before {
        background: rgba(255, 255, 255, 0.1);
      }

      &:after {
        background: rgba(255, 255, 255, 0.5);
      }
    }

    a.active {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .side-menu .side-menu__icon, .side-menu__item, .slide-item {
    color: rgba(255, 255, 255, 0.7);
  }
}

/*Style1*/

body.white-mode {
  background-color: $white;
}

.white-mode {
  .card {
    background-color: $white;
    border: 1px solid #dfe4f5;
    border-radius: 0;
    box-shadow: none;
  }

  .app-sidebar {
    background: $white !important;
    border-right: 1px solid rgba(211, 220, 233, 0.5);
  }

  .side-header {
    border-right: 1px solid rgba(211, 220, 233, 0.5);
    border-bottom: 1px solid rgba(211, 220, 233, 0.5);
  }

  .side-menu__item {
    &.active, &:hover, &:focus {
      background: #f1f3fd;
      box-shadow: 0 0 40px 0 rgba(234, 238, 249, 0.5);
      border-radius: 5px;
    }
  }
}

.sidemenu-bgimage.white-mode .side-header {
  border-bottom: 2px solid transparent;
}

/*color-menu*/

.white-mode {
  &.color-menu {
    .app-sidebar .sidebar-navs, .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .slide-item {
      &.active, &:hover, &:focus {
        color: rgba(255, 255, 255, 0.4);
      }

      color: $white;
    }

    .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .side-menu h3 {
      color: rgba(255, 255, 255, 0.3);
      opacity: 9;
    }

    .slide-menu {
      li.active {
        &:before {
          background: rgba(255, 255, 255, 0.1);
        }

        &:after {
          background: rgba(255, 255, 255, 0.5);
        }
      }

      a.active {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .slide-item {
      &.active, &:hover, &:focus {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .side-menu .side-menu__icon, .side-menu__item, .slide-item {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &.dark-menu {
    .app-sidebar {
      background: #1e283e  !important;
    }

    .side-header {
      background: #1e283e;
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .side-menu h3 {
      color: #c5c9e6;
      opacity: .4;
    }

    .slide-menu a.active, .side-menu .side-menu__icon {
      color: #c5c9e6;
    }

    .side-menu__item {
      color: #c5c9e6;

      &.active, &:hover, &:focus {
        box-shadow: 0px 1px 5px 0 rgba(7, 16, 31, 0.1);
      }
    }
  }

  &.gradient-menu {
    .app-sidebar {
      background: linear-gradient(to top, $primary 0%, #a44de7 100%) !important;

      .sidebar-navs {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .sidebar-navs a {
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    &.slide-item.active {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &.color-menu .slide-item:hover {
    color: rgba(255, 255, 255, 0.4);
  }

  &.gradient-menu {
    .slide-item {
      &:focus {
        color: rgba(255, 255, 255, 0.4);
      }

      color: $white;
    }

    .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .side-menu h3 {
      color: rgba(255, 255, 255, 0.3);
      opacity: 9;
    }

    .slide-menu {
      li.active:before {
        background: rgba(255, 255, 255, 0.1);
      }

      a.active {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .slide-item {
      &.active, &:hover, &:focus {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .side-menu .side-menu__icon, .side-menu__item, .slide-item {
      color: rgba(255, 255, 255, 0.7);
    }

  }

  &.sidemenu-bgimage {
    &.color-menu {
      .app-sidebar {
        background: url(../images/media/bg.jpg) !important;
        background-size: cover;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .side-header {
        border-bottom: 2px solid transparent;
      }
    }

    &.dark-menu {
      .app-sidebar {
        background: url(../images/media/bg.jpg) !important;
        background-size: cover;

        &:before {
          content: '';
          background: rgba(30, 40, 62, 0.89);
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .side-header {
        border-bottom: 2px solid transparent;
      }
    }

    &.gradient-menu {
      .app-sidebar {
        background: url(../images/media/bg.jpg) !important;
        background-size: cover;

        &:before {
          content: '';
          background: linear-gradient(to top, rgba(68, 124, 236, 0.94) 0%, rgba(164, 77, 231, 0.94) 100%) !important;
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .side-header {
        border-bottom: 2px solid transparent;
      }
    }
  }
}

/*Dark-menu*/

/*gradient-menu*/

/*color-menu*/

/*Dark-menu*/

/*Garident-menu*/

/*color-menu*/

.dark-mode.color-menu {
  .app-sidebar .sidebar-navs, .side-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-navs a {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: rgba(255, 255, 255, 0.4);
    }

    color: $white;
  }
}

.sidebar-mini.sidenav-toggled.color-menu {
  .side-header {
    .light-logo {
      display: none;
    }

    .toggle-logo {
      display: block !important;
    }
  }

  &.sidenav-toggled-open .side-header {
    .toggle-logo {
      display: none !important;
    }

    .header-brand-img {
      &.light-logo1 {
        display: none !important;
      }

      &.desktop-logo {
        display: block !important;
      }
    }
  }
}

.dark-mode {
  &.color-menu {
    .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .side-menu h3 {
      color: rgba(255, 255, 255, 0.3);
      opacity: 9;
    }

    .slide-menu {
      li.active:before {
        background: rgba(255, 255, 255, 0.1);
      }

      a.active {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .slide-item {
      &.active, &:hover, &:focus {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .side-menu .side-menu__icon, .side-menu__item, .slide-item {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &.dark-menu {
    .app-sidebar {
      background: #1e283e   !important;
    }

    .side-header {
      background: #131632;
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }
  }

  &.white-mode.dark-menu .slide-item {
    color: $white;
  }

  &.dark-menu {
    .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .side-menu h3 {
      color: #c5c9e6;
      opacity: .4;
    }

    .slide-menu a.active, .side-menu .side-menu__icon, .side-menu__item {
      color: #c5c9e6;
    }
  }

  &.gradient-menu {
    .app-sidebar {
      background: linear-gradient(to top, $primary 0%, #a44de7 100%) !important;

      .sidebar-navs {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .sidebar-navs a {
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    &.slide-item.active {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &.color-menu .slide-item:hover {
    color: rgba(255, 255, 255, 0.4);
  }

  &.gradient-menu {
    .slide-item {
      &:focus {
        color: rgba(255, 255, 255, 0.4);
      }

      color: $white;
    }

    .side-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .side-menu h3 {
      color: rgba(255, 255, 255, 0.3);
      opacity: 9;
    }

    .slide-menu {
      li.active:before {
        background: rgba(255, 255, 255, 0.1);
      }

      a.active {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .slide-item {
      &.active, &:hover, &:focus {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .side-menu .side-menu__icon, .side-menu__item, .slide-item {
      color: rgba(255, 255, 255, 0.7);
    }

  }

  &.sidemenu-bgimage {
    &.color-menu {
      .app-sidebar {
        background: url(../images/media/bg.jpg) !important;
        background-size: cover;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .side-header {
        border-bottom: 2px solid transparent;
      }
    }

    &.dark-menu {
      .app-sidebar {
        background: url(../images/media/bg.jpg) !important;
        background-size: cover;

        &:before {
          content: '';
          background: rgba(24, 27, 49, 0.95);
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .side-header {
        border-bottom: 2px solid transparent;
      }
    }

    &.gradient-menu {
      .app-sidebar {
        background: url(../images/media/bg.jpg) !important;
        background-size: cover;

        &:before {
          content: '';
          background: linear-gradient(to top, rgba(68, 124, 236, 0.94) 0%, rgba(164, 77, 231, 0.94) 100%) !important;
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .side-header {
        border-bottom: 2px solid transparent;
      }
    }
  }

  &.light-menu {
    .app-sidebar {
      background: $white !important;
      border-right: 1px solid  #e0e4ec;
    }

    .side-header {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .app-sidebar .sidebar-navs {
      border-bottom: 1px solid #e0e4ec;
    }

    .slide-item.active {
      color: $primary;
    }

    .slide-menu li.active {
      &:before, &:after {
        background: #e0e4ec;
      }
    }

    .side-menu__item {
      color: #677ca7;
    }

    .slide-item {
      color:$color;
    }

    .side-menu .side-menu__icon {
      fill: #677ca7;
    }

    .sidebar-navs a {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #e0e4ec !important;
      color: #68798b !important;
    }

    &.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img {
      &.light-logo {
        display: none;
        margin-right: 0;
      }

      &.toggle-logo, &.light-logo1 {
        display: none;
      }
    }

    .side-header {
      background: $white;
    }

    &.sidemenu-bgimage .app-sidebar {
      background: url(../images/media/bg.jpg) !important;
      background-size: cover;

      &:before {
        content: '';
        position: absolute;
        background: rgba(255, 255, 255, 0.9);
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

/*Dark-menu*/

/*gradient-menu*/

/*color-menu*/

/*Dark-menu*/

/*Garident-menu*/

/*Light-menu*/

/*---------------- Header-----------------*/
@media (max-width: 767px) {
  .dark-menu .mobile-header {
    background: #0f091f !important;

    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }

    .nav-link.icon i, .navbar-toggler-icon, .header-settings .nav-link.icon i {
      color: $white !important;
    }
  }
}

@media (max-width: 767px) {
  .color-hor-header .mobile-header {
    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }

    .nav-link.icon i, .navbar-toggler-icon, .header-settings .nav-link.icon i {
      color: $white !important;
    }
  }

  .color-menu .mobile-header {
    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }

    .nav-link.icon i, .navbar-toggler-icon, .header-settings .nav-link.icon i {
      color: $white !important;
    }
  }
}

.color-hor-header .animated-arrow span {
  background: $white !important;

  &:before, &:after {
    background: $white !important;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .color-hor-header .mobile-header {
    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }

    .nav-link.icon i {
      color: #76839a !important;
    }

    .navbar-toggler-icon, .header-settings .nav-link.icon i {
      color: $white !important;
    }
  }
}

@media (max-width: 767px) {
  .gradient-hor-header .mobile-header {
    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }

    .nav-link.icon i {
      color: #76839a !important;
    }

    .navbar-toggler-icon, .header-settings .nav-link.icon i {
      color: $white !important;
    }

    background: linear-gradient(to left, #0250c5 0%, #cd69d9 100%) !important;
  }

  .gradient-menu .mobile-header {
    background: linear-gradient(to left, #0250c5 0%, #cd69d9 100%) !important;

    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }

    .nav-link.icon i, .navbar-toggler-icon, .header-settings .nav-link.icon i {
      color: $white !important;
    }
  }

  .white-mode .mobile-header {
    background: #f0f1fb;
    border-bottom: 1px solid #dfe4f5;
    box-shadow: 0 0 40px 0 rgba(234, 238, 249, 0.5);

    .app-sidebar__toggle {
      color: #76839a;
    }
  }

  .dark-mode .mobile-header {
    background-color: #30304d;
    border: 1px solid transparent;
    box-shadow: 0 8px 24px #2a2635;

    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }
  }

  .white-mode {
    &.light-menu .mobile-header {
      background: $white;
    }

    &.dark-menu .mobile-header .app-sidebar__toggle, &.gradient-menu .mobile-header .app-sidebar__toggle, &.color-menu .mobile-header .app-sidebar__toggle {
      color: $white;
    }
  }

  .dark-mode {
    &.dark-menu .mobile-header {
      background-color: #30304d !important;
    }

    &.light-menu .mobile-header {
      background: $white;

      .header-brand-img {
        &.desktop-logo {
          display: none;
        }

        &.mobile-light {
          display: block;
        }
      }

      .navbar-toggler-icon, .header-settings .nav-link.icon i {
        color: #76839a !important;
      }
    }

    &.light-hor-header .mobile-header {
      background: $white;

      .header-brand-img {
        &.desktop-logo {
          display: none;
        }

        &.mobile-light {
          display: block;
        }
      }

      .navbar-toggler-icon, .header-settings .nav-link.icon i {
        color: #76839a !important;
      }
    }
  }
}

.dark-mode.light-hor-header .animated-arrow span {
  background: #76839a !important;

  &:before, &:after {
    background: #76839a !important;
  }
}

.gradient-hor-header .animated-arrow span {
  background: $white !important;

  &:before, &:after {
    background: $white !important;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .gradient-hor-header .mobile-header {
    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.mobile-light {
        display: none;
      }
    }

    .nav-link.icon i {
      color: #76839a !important;
    }

    .navbar-toggler-icon, .header-settings .nav-link.icon i {
      color: $white !important;
    }

    background: linear-gradient(to left, #0250c5 0%, #cd69d9 100%) !important;
  }

  .dark-mode.light-hor-header .header.hor-header {
    background: $white;

    .header-brand-img {
      &.desktop-logo {
        display: block;
      }

      &.light-logo {
        display: none;
      }
    }

    .nav-link.icon i {
      color: #76839a !important;
    }
  }
}

/*white-mode*/

.white-mode {
  .footer {
    background: $white;
    border-top: 1px solid #dfe4f5;
  }

  .horizontal-main.hor-menu {
    background: $white;
    border-bottom: 1px solid #dfe4f5;
    box-shadow: none;
  }

  .header {
    background: $white;
    border-bottom: 1px solid #dfe4f5;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active, &:hover {
      background: #f1f3fd;
      box-shadow: 0 0 40px 0 rgba(234, 238, 249, 0.5);
      border-radius: 5px;
    }
  }

  .header .form-inline .form-control {
    background: $white;
  }

  .list-group-item {
    background-color: $white;
  }
}

/*dark-mode*/

.dark-mode {
  .horizontal-main.hor-menu {
    background: #131632;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active {
      background: #202540;
    }

    &:hover {
      background: #202540;
      color: #c5c9e6;
    }
  }

  .horizontalMenu {
    > .horizontalMenu-list > li > a {
      color: #c5c9e6;
    }

    h3 {
      color: #70789c;
    }
  }
}

/*Light header*/

.light-hor-header {
  .hor-header.header {
    background: $white;
    border-bottom: 1px solid rgba(215, 227, 243, 0.5);
  }

  .header .form-inline .form-control {
    border: 1px solid rgba(215, 227, 243, 0.5);
    box-shadow: none;
  }

  .nav-link.icon svg, .navbar-toggler svg {
    fill: #677ca7;
  }

  .animated-arrow span {
    background: #677ca7;

    &:before, &:after {
      background: #677ca7;
    }
  }

  .header .nav-link .text-dark {
    color:$color !important;
  }

  .hor-header .header-brand-img {
    &.desktop-logo.mobile-logo {
      display: none;
    }

    &.mobile-icon {
      display: block;
    }
  }

  &.white-mode .hor-header.header {
    background: $white;
    border-bottom: 1px solid rgba(211, 220, 233, 0.7);
  }

  .app-header.header {
    background: $white !important;
    border-bottom: 1px solid rgba(215, 227, 243, 0.5);
  }

  &.white-mode .app-header {
    background: $white;
    border-bottom: 1px solid #dfe4f5;
  }

  .side-header {
    background: $white !important;
    border-bottom: 1px solid rgba(211, 220, 233, 0.5);
    border-right: 1px solid rgba(211, 220, 233, 0.5);
  }

  .app-sidebar__toggle svg {
    fill: #677ca7;
  }

  .side-header .header-brand-img.desktop-logo {
    display: none;
  }
}

.sidebar-mini.sidenav-toggled.light-hor-header .side-header .header-brand-img.toggle-logo {
  display: none;
}

.light-hor-header .side-header .header-brand-img.light-logo1 {
  display: block;
}

.sidebar-mini.sidenav-toggled {
  &.light-hor-header .side-header .header-brand-img.light-logo {
    display: block !important;
  }

  &.sidenav-toggled-open.light-hor-header .app-sidebar .side-header .header-brand-img {
    &.desktop-logo, &.light-logo {
      display: none !important;
    }

    &.light-logo1 {
      display: block !important;
    }
  }
}

@media (min-width: 991px) {
  .light-hor-header .top-header .header-brand2 .header-brand-img{
    &.desktop-logo1 {
      display: none;
    }

    &.desktop-logo {
      display: block;
    }
  }
}

/*Dark header*/

.dark-hor-header {
  .hor-header.header {
    background: #1e283e;
  }

  &.dark-mode .hor-header.header {
    background: #131632;
    border-bottom: 1px solid #202440;
  }

  .header .form-inline .form-control {
    background: #26324c;
    color: $white !important;
	border:1px solid transparent;

    &.header-search {
      &::placeholder {
        color: $white;
        opacity: 0.4;
      }

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.05) !important;
        color: $white !important;
        background: #26324c !important;
      }
    }
  }

  .form-control.header-search:focus {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    color: $white !important;
    background: #26324c !important;
  }

  .app-header, .side-header {
    background: #1e283e !important;
	border-right:1px solid rgba(255,255,255,0.1);
  }

  &.dark-mode {
    .app-header {
      background: #131632 !important;
    }

    .side-header {
      background: #131632 !important;
      border-right: 1px solid #202440;
      border-bottom: 1px solid #202440;
    }
  }
  .side-header .header-brand-img {
    &.desktop-logo {
      display: block;
    }

    &.light-logo1 {
      display: none;
    }
  }

  &.sidebar-mini.sidenav-toggled .side-header {
    .light-logo {
      display: none;
    }

    .header-brand-img.toggle-logo {
      display: block !important;
    }
  }

  &.app.sidebar-mini.sidenav-toggled-open .side-header .header-brand-img {
    &.light-logo1 {
      display: none !important;
    }

    &.desktop-logo {
      display: block !important;
    }
  }
}

/*color header*/

.color-hor-header {
  .hor-header .header-brand-img {
    &.mobile-icon {
      display: none;
    }

    &.desktop-logo.mobile-logo {
      display: block;
    }
  }

  .nav-link.icon i, .text-dark {
    color: $white !important;
  }
}

.dark-mode.color-hor-header .header.hor-header {
  border-bottom: 1px solid transparent;
}

.color-hor-header .app-sidebar__toggle {
  color: $white;

  &:focus, &:hover {
    color: $white;
  }
}

/*Gradient-Header*/

.gradient-hor-header {
  .side-header {
    background: #9255e8 !important;
	border-right:1px solid rgba(255,255,255,0.1);
  }

  .header.hor-header, .app-header {
    background: linear-gradient(to left, $primary 0%, #a44de7 100%) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .app-sidebar__toggle {
    color: $white;

    &:focus, &:hover {
      color: $white;
    }
  }

  .hor-header .header-brand-img {
    &.mobile-icon {
      display: none;
    }

    &.desktop-logo.mobile-logo {
      display: block;
    }
  }

  .nav-link.icon i, .text-dark {
    color: $white !important;
  }
  .side-header .header-brand-img {
    &.desktop-logo {
      display: block;
    }

    &.light-logo1 {
      display: none;
    }
  }

  &.sidebar-mini.sidenav-toggled .side-header {
    .light-logo {
      display: none;
    }

    .header-brand-img.toggle-logo {
      display: block !important;
    }
  }

  &.app.sidebar-mini.sidenav-toggled-open .side-header .header-brand-img {
    &.light-logo1 {
      display: none !important;
    }

    &.desktop-logo {
      display: block !important;
    }
  }
  
}

.dark-mode.gradient-hor-header .header.hor-header {
  border-bottom: 1px solid transparent;
}

/*---------- Horizonatl-menu---------------*/

.white-mode {
  &.color-hor-menu {
    .horizontalMenu > .horizontalMenu-list > li > a {
      color: $white;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
      &.active, &:hover {
        color: rgba(255, 255, 255, 0.7);
        background: rgba(0, 0, 0, 0.1);
        box-shadow: none;
      }
    }
  }

  &.gradient-hor-menu {
    .hor-header.header {
      border-bottom: 1px solid transparent;
    }

    .horizontalMenu > .horizontalMenu-list > li > a {
      color: $white;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
      &:last-child {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }

      > a {
        &.active, &:hover {
          color: rgba(255, 255, 255, 0.7);
          background: rgba(0, 0, 0, 0.1);
          box-shadow: none;
        }
      }
    }
  }
}

.dark-mode {
  &.color-hor-menu {
    .horizontalMenu > .horizontalMenu-list > li > a {
      color: $white;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
      &.active, &:hover {
        color: rgba(255, 255, 255, 0.7);
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.gradient-hor-menu {
    .horizontalMenu > .horizontalMenu-list > li > a {
      color: $white;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
      &:last-child {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }

      > a {
        &.active, &:hover {
          color: rgba(255, 255, 255, 0.7);
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.light-mode {
  &.color-hor-menu {
    .horizontalMenu > .horizontalMenu-list > li > a {
      color: $white;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
      &.active, &:hover {
        color: rgba(255, 255, 255, 0.7);
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.gradient-hor-menu {
    .horizontalMenu > .horizontalMenu-list > li > a {
      color: $white;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
      &.active, &:hover {
        color: rgba(255, 255, 255, 0.7);
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

/*Light-menu*/

.light-hor-menu {
  .horizontal-main.hor-menu {
    background: $white;
    border-bottom: 1px solid rgba(215, 227, 243, 0.5);
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active .hor-icon, &:hover .hor-icon {
      fill: $white;
    }
  }
}

@media only screen and (max-width: 991px) {
  .light-hor-menu {
    .horizontalMenu > .horizontalMenu-list {
      background: $white;

      > li > {
        a {
          border-bottom-color: rgba(215, 227, 243, 0.5);
          border-right: 0;
        }

        ul.sub-menu {
          background-color: $white;

          > li > a {
            color: $primary;
          }
        }

        .horizontalMenu-click > i {
          color: $primary;
          opacity: 0.5;
        }

        ul.sub-menu {
          > li > ul.sub-menu {
            background-color: transparent;

            > li > a {
              color: $primary;
            }
          }

          li:hover > a {
            background-color: #f2f3f9;
            color: #7d7d84;
          }
        }
      }
    }

    .mega-menubg {
      background: #f2f3f9 !important;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
      > .horizontal-megamenu .link-list li a {
        background: #f2f3f9 !important;
        color: #7d7d84 !important;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

/*Dark-menu*/

.dark-hor-menu {
  .header.hor-header {
    border-bottom: 1px solid transparent;
  }

  .horizontal-main.hor-menu {
    background: #1e283e;
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    color: #c5c9e6;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active .hor-icon, &:hover .hor-icon {
      fill: $white;
    }
  }

  .hor-icon {
    fill: #c5c9e6;
  }
}

@media only screen and (max-width: 991px) {
  .dark-hor-menu {
    .horizontalMenu > .horizontalMenu-list {
      background: #0f091f;

      > li > {
        a {
          border-right: 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.07);
        }

        ul.sub-menu > li > a, .horizontalMenu-click > i {
          color: #c5c9e6;
        }

        ul.sub-menu {
          background-color: #0f091f;
          border-bottom: 1px solid rgba(255, 255, 255, 0.07);

          > li > ul.sub-menu {
            background-color: transparent;
          }

          li:hover {
            > a {
              color: #c5c9e6 !important;
            }

            > a {
              background-color: #0f091f;
            }
          }

          > li > ul.sub-menu > li > a {
            color: #c5c9e6 !important;
          }
        }
      }
    }

    .mega-menubg {
      background: #0f091f !important;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      background: #0f091f !important;
      color: #c5c9e6;
    }

    .sub-menu li a:before {
      color: #c5c9e6;
    }
  }
}

.dark-mode {
  &.dark-hor-menu {
    .horizontal-main.hor-menu {
      background: #131632;
    }

    .header.hor-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    .horizontal-main.hor-menu {
      border-bottom: 1px solid rgba(255, 255, 255, 0.01) !important;
    }
  }

  .horizontal-main.hor-menu {
    border-bottom: 1px solid #191c31 !important;
    box-shadow: 0 20px 24px #272d47;
  }

  &.color-hor-menu .horizontal-main.hor-menu, &.gradient-hor-menu .horizontal-main.hor-menu {
    border-bottom: 1px solid transparent;
  }
}

/*color-menu*/

.color-hor-menu {
  .hor-header.header {
    background: $white;
    border-bottom: 1px solid rgba(215, 227, 243, 0.5);
  }

  .header .form-inline .form-control {
    border: 1px solid rgba(215, 227, 243, 0.5);
    box-shadow: none;
  }

  .nav-link.icon svg {
    fill: #677ca7;
  }
  .horizontalMenu > .horizontalMenu-list > li > a {
    color: $white;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active, &:hover {
      color: rgba(255, 255, 255, 0.7);
      background: rgba(0, 0, 0, 0.1);
    }

    &.active .hor-icon, &:hover .hor-icon {
      fill: rgba(255, 255, 255, 0.7);
    }
  }

  .hor-icon {
    fill: $white;
  }
}

/*Gradient-menu*/

.gradient-hor-menu {
  .horizontal-main.hor-menu {
    background: linear-gradient(to left, $primary 0%, #a44de7 100%) !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    color: $white;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active .hor-icon, &:hover .hor-icon {
      fill: rgba(255, 255, 255, 0.7);
    }
  }

  .hor-icon {
    fill: $white;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active, &:hover {
      color: rgba(255, 255, 255, 0.7);
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .sub-menu li:before, .mega-menubg li a:before {
    opacity: 0.2;
  }
}

@media only screen and (max-width: 991px) {
  .gradient-hor-menu {
    .horizontalMenu > .horizontalMenu-list {
      background: linear-gradient(to left, $primary 0%, #a44de7 100%);

      > li > {
        a {
          border-right: 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          color: $white;
        }

        ul.sub-menu > li > a {
          color: $white !important;
        }

        .horizontalMenu-click > i {
          color: rgba(255, 255, 255, 0.1);
        }

        ul.sub-menu {
          background-color: #170e2d;

          > li > ul.sub-menu {
            background-color: #0f091f;
          }

          li:hover {
            > a {
              color: rgba(255, 255, 255, 0.7) !important;
            }

            > a {
              background-color: #170e2d;
            }
          }

          > li > ul.sub-menu > li > a {
            color: rgba(255, 255, 255, 0.7) !important;
          }
        }
      }
    }

    .mega-menubg {
      background: #170e2d !important;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      background: #170e2d !important;
      color: rgba(255, 255, 255, 0.7);
    }

    .sub-menu li a:before {
      color: rgba(255, 255, 255, 0.2);
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
      &.active, &:hover {
        color: $white;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
      background-color: rgba(0, 0, 0, 0.04);
      border-bottom: 0;
    }

    .mega-menubg {
      background: rgba(0, 0, 0, 0.04) !important;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      background: rgba(0, 0, 0, 0.04) !important;
      color: rgba(255, 255, 255, 0.6) !important;
    }

    .horizontalMenu {
      > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
        background-color: transparent;
      }

      h3 {
        color: $white;
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
      &:last-child {
        border-right: 0;
      }

      &:first-child {
        border-left: 0;
      }
    }
  }
}

/*color-hormenu*/
@media only screen and (max-width: 991px) {
  .color-hor-menu {
    .horizontalMenu > .horizontalMenu-list {
      background: linear-gradient(to top, #0250c5 0%, #cd69d9 100%);

      > li > {
        a {
          border-right: 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          color: $white;
        }

        ul.sub-menu > li > a {
          color: rgba(255, 255, 255, 0.7);
        }

        .horizontalMenu-click > i {
          color: rgba(255, 255, 255, 0.1);
        }

        ul.sub-menu {
          background-color: #170e2d;

          > li > ul.sub-menu {
            background-color: #0f091f;
          }

          li:hover > a, > li > ul.sub-menu > li > a {
            color: rgba(255, 255, 255, 0.7) !important;
          }
        }
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    .sub-menu li a:before {
      color: rgba(255, 255, 255, 0.2);
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
      &.active, &:hover {
        color: rgba(255, 255, 255, 0.7);
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .horizontalMenu h3 {
      color: $white;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
      &:last-child {
        border-right: 0;
      }

      &:first-child {
        border-left: 0;
      }
    }

    .navbar-toggler svg {
      fill: #677ca7;
    }

    .animated-arrow span {
      background: #677ca7;

      &:before, &:after {
        background: #677ca7;
      }
    }
  }
}

/*Horizonatl-menu*/

.dark-mode {
  &.gradient-hor-header .nav-link.icon i, &.color-hor-header .nav-link.icon i {
    color: $white !important;
  }

  &.light-hor-menu .header.hor-header {
    border-bottom: 1px solid transparent;
  }
}

.white-mode.dark-hor-menu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
  &.active, &:hover {
    box-shadow: none;
  }
}

.dark-mode.light-hor-menu .hor-icon {
  fill: #677ca7;
}

.light-hor-menu.white-mode .header.hor-header {
  border-bottom: 1px solid transparent;
}

/*Toggle menu */

.white-mode {
  .app-sidebar.toggle-sidemenu {
    background: none !important;
    border-right: 0 !important;
  }

  .first-sidemenu {
    background: $white;
  }

  .second-sidemenu {
    background: $white;
    border-right: 1px solid #dfe4f5;
  }

  .resp-vtabs {
    .resp-tabs-list li {
      border: 1px solid #dfe4f5 !important;
      border-left: 0 !important;
      border-top: 0 !important;
      border-bottom: 0;
    }

    li {
      &.active, &.resp-tab-active {
        border-right: 0 !important;
      }
    }
  }

  .first-sidemenu li.active {
    background: #f1f3fd;
  }
}

.light-menu {
  .first-sidemenu, .second-sidemenu {
    background: $white;
  }
}

.dark-menu {
  .first-sidemenu, .second-sidemenu {
    background: #1e283e;
  }

  .resp-vtabs .resp-tabs-list li {
    border: 1px solid rgba(255, 255, 255, 0.04) !important;
    border-left: 0 !important;
    border-top: 0 !important;
  }

  .toggle-sidemenu .side-menu__icon {
    fill: #c5c9e6;
  }

  .resp-vtabs li {
    &.active, &.resp-tab-active {
      border-right: 0 !important;
    }
  }

  .toggle-sidemenu .first-sidemenu li {
    &.active, &:hover {
      background: #1b2438;
    }
  }

  &.white-mode .header {
    border-bottom: 1px solid transparent;
  }

  .toggle-sidemenu {
    .second-sidemenu h5 {
      color: #c5c9e6;
      opacity: .4;
    }

    .side-menu .slide.submenu a {
      color: #c5c9e6;
    }

    .side-menu__item {
      &.active, &:hover, &:focus {
        background: transparent;
      }
    }
  }
}

.color-menu {
  .first-sidemenu, .second-sidemenu {
    background: $primary;
  }

  .resp-vtabs .resp-tabs-list li {
    border: 1px solid rgba(255, 255, 255, 0.04) !important;
    border-left: 0 !important;
    border-top: 0 !important;
  }

  .toggle-sidemenu .side-menu__icon {
    color: #c5c9e6;
  }

  .resp-vtabs li {
    &.active, &.resp-tab-active {
      border-right: 0 !important;
    }
  }

  .toggle-sidemenu {
    .second-sidemenu h5 {
      color: #c5c9e6;
      opacity: .4;
    }

    .side-menu .slide.submenu a {
      color: #c5c9e6;
    }

    .side-menu__item {
      &.active, &:hover, &:focus {
        background: transparent;
        box-shadow: none;
      }
    }

    .first-sidemenu li {
      &.active, &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.gradient-menu {
  .first-sidemenu {
    background: linear-gradient(to top, $primary 0%, #a44de7 100%) !important;
  }

  .second-sidemenu {
    background: linear-gradient(to top, $primary 0%, #a44de7 100%);
  }

  .resp-vtabs .resp-tabs-list li {
    border: 1px solid rgba(255, 255, 255, 0.04) !important;
    border-left: 0 !important;
    border-top: 0 !important;
  }

  .toggle-sidemenu .side-menu__icon {
    color: $white;
  }

  .resp-vtabs li {
    &.active, &.resp-tab-active {
      border-right: 0 !important;
    }
  }

  .toggle-sidemenu {
    .second-sidemenu h5 {
      color: $white;
      opacity: .4;
    }

    .side-menu .slide.submenu a {
      color: $white;
    }

    .side-menu__item {
      &.active, &:hover, &:focus {
        background: transparent;
        box-shadow: none;
      }
    }
  }
}

.light-menu {
  &.dark-mode {
    .first-sidemenu {
      background: $white !important;
    }

    .second-sidemenu {
      background: $white;
    }

    .first-sidemenu li.resp-tab-item.active {
      background: $white !important;
    }

    .resp-vtabs {
      li.resp-tab-active {
        background: $white !important;
      }

      .resp-tabs-list li {
        border-right: 1px solid #e0e4ec !important;
        border: 1px solid #e0e4ec !important;
        border-left: 0 !important;
        border-top: 0 !important;
      }

      ul.resp-tabs-list {
        background: $white;
      }
    }

    .toggle-sidemenu .side-menu__icon {
      color: $primary;
    }

    .resp-vtabs li {
      &.active, &.resp-tab-active {
        border-right: 0 !important;
      }
    }

    .second-sidemenu h5 {
      color: #7083ab;
    }

    .toggle-sidemenu {
      .slide-item {
        &.active, &:hover, &:focus {
          text-decoration: none;
          color: $primary;
        }

        color:$color;
      }

      .side-menu__icon {
        fill: #677ca7;
      }

      .first-sidemenu li.active {
        background: #f1f3fd;
      }

      .side-menu .slide.submenu a {
        color:$color;
        background: none;
        box-shadow: none;
      }
    }

    .first-sidemenu li {
      &.active svg, &:hover svg {
        fill: #445579;
      }
    }

    .toggle-sidemenu .slide-item.active:before {
      background: $primary;
    }
  }

  &.white-mode {
    .first-sidemenu {
      background: $white;
    }

    .second-sidemenu {
      background: $white;
      border-right: 1px solid #dfe4f5;
    }
  }
}

.dark-menu.dark-mode {
  .first-sidemenu {
    background: #131632 !important;
  }

  .second-sidemenu {
    background: #131632;
  }

  .first-sidemenu li.resp-tab-item.active {
    background: #1c2038  !important;
  }

  .resp-vtabs {
    li.resp-tab-active {
      background: #1c2038  !important;
    }

    .resp-tabs-list li {
      border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
      border: 1px solid rgba(255, 255, 255, 0.05) !important;
      border-left: 0 !important;
      border-top: 0 !important;
    }

    ul.resp-tabs-list {
      background: #131632;
    }

    li {
      &.active, &.resp-tab-active {
        border-right: 0 !important;
      }
    }
  }
}

.color-menu.dark-mode {
  .first-sidemenu {
    background: $primary  !important;
  }

  .second-sidemenu {
    background: $primary;
  }

  .first-sidemenu li.resp-tab-item.active {
    background: $primary  !important;
  }

  .resp-vtabs {
    li.resp-tab-active {
      background: $primary  !important;
    }

    .resp-tabs-list li {
      border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
      border: 1px solid rgba(255, 255, 255, 0.05) !important;
      border-left: 0 !important;
      border-top: 0 !important;
    }

    ul.resp-tabs-list {
      background: $primary;
    }

    li {
      &.active, &.resp-tab-active {
        border-right: 0 !important;
      }
    }
  }
}

.gradient-menu.dark-mode {
  .first-sidemenu {
    background: linear-gradient(to top, $primary 0%, #a44de7 100%) !important;
  }

  .second-sidemenu {
    background: linear-gradient(to top, $primary 0%, #a44de7 100%);
  }

  .first-sidemenu li.resp-tab-item.active {
    background: rgba(0, 0, 0, 0.05) !important;
  }

  .resp-vtabs {
    li.resp-tab-active {
      background: rgba(0, 0, 0, 0.05) !important;
    }

    .resp-tabs-list li {
      border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
      border: 1px solid rgba(255, 255, 255, 0.05) !important;
      border-left: 0 !important;
      border-top: 0 !important;
    }

    ul.resp-tabs-list {
      background: linear-gradient(to top, $primary 0%, #a44de7 100%);
    }

    li {
      &.active, &.resp-tab-active {
        border-right: 0 !important;
      }
    }
  }

  .toggle-sidemenu .side-menu__icon {
    color: $white;
  }
}

.color-menu {
  &.dark-mode .toggle-sidemenu {
    .slide-item:before, .side-menu__item:before {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &.white-mode .header {
    border-bottom: 1px solid transparent;
  }
}

.gradient-menu {
  .first-sidemenu li {
    &.active, &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  &.white-mode .header {
    border-bottom: 1px solid transparent;
  }
}

.light-menu .app-sidebar.toggle-sidemenu, .dark-menu .app-sidebar.toggle-sidemenu, .color-menu .app-sidebar.toggle-sidemenu, .gradient-menu .app-sidebar.toggle-sidemenu {
  background: none !important;
  border-right: 0 !important;
}

.light-hor-menu.dark-mode .horizontalMenu > .horizontalMenu-list > li > a {
  color: #677ca7;
}

/*Color-menu*/
.color-hor-header {
  .app-header, .side-header {
    background: #525ce5 !important;
  }

  .header .nav-link .text-dark {
    color: #c4d2ec !important;
  }

  .nav-link.icon svg, .app-sidebar__toggle svg {
    fill: #c4d2ec;
  }

  .side-header {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .header .form-inline .form-control {
    box-shadow: none;
  }

  .side-header .header-brand-img {
    &.desktop-logo {
      display: block;
    }

    &.light-logo1 {
      display: none;
    }
  }

  &.sidebar-mini.sidenav-toggled .side-header {
    .light-logo {
      display: none;
    }

    .header-brand-img.toggle-logo {
      display: block !important;
    }
  }

  &.app.sidebar-mini.sidenav-toggled-open .side-header .header-brand-img {
    &.light-logo1 {
      display: none !important;
    }

    &.desktop-logo {
      display: block !important;
    }
  }
}

.color-hor-header .hor-header.header {
  background: #525ce5;
}

.gradient-hor-header {
  .app-sidebar__toggle svg, .nav-link.icon svg {
    fill: #c4d2ec;
  }
}

.dark-mode {
  &.gradient-hor-header .header .form-inline .form-control, &.color-hor-header .header .form-inline .form-control {
    border: 1px solid transparent;
    background: #ffffff;
    color: #131632 !important;

    &:focus {
      border: 1px solid transparent;
      background: #ffffff;
      color: #131632 !important;
    }
  }

  &.light-hor-header {
    .header .form-inline .form-control {
      border: 1px solid rgba(229, 234, 249, 0.9);
      background: #ffffff;
      color: #131632 !important;

      &:focus {
        border: 1px solid rgba(229, 234, 249, 0.9);
        background: #ffffff;
        color: #131632 !important;
      }
    }

    .app-sidebar__toggle svg, .nav-link.icon svg {
      fill: #677ca7;
    }

    .side-header .header-brand-img {
      &.desktop-logo {
        display: none;
      }

      &.light-logo1 {
        display: block;
      }
    }

    &.light-menu .side-header {
      border-bottom: 1px solid rgba(234, 238, 251, 0.8);
    }
  }
}
.dark-mode.light-hor-header .hor-header.header{
	background:#fff;
	border-bottom:1px solid rgba(234, 238, 251, 0.8);
}
@media (max-width: 767px) {
  .dark-hor-header .header .header-brand-img, .color-hor-header .header .header-brand-img, .gradient-hor-header .header .header-brand-img {
    &.mobile-icon {
      display: none;
    }

    &.desktop-logo.mobile-logo {
      display: block;
      margin-top: 5px;
    }
  }
}